import React from "react"
import Text from "../../components/Text"
import { Columns } from "../../components/Containers"

const StaticHero = ({ heading, blurb }) => {
  const styles = {
    bg: {
      backgroundImage: `url(https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/BWOS/DEV/home-page-hero)`,
      backgroundSize: "cover",
      backgroundPosition: "top",
      backgroundRepeat: "no-repeat"
    }
  }

  return (
    <div className="homepage-hero" style={styles.bg}>
      <Columns sideColumnsSize={2}>
        <div className="column">
          <div className="hero-heading">
            <Text
              role="heading"
              as="p"
              className="homepage-hero__slogan"
              text={heading}
            />
            <Text as="h1" className="homepage-hero__heading" text={blurb} />
          </div>
        </div>
      </Columns>
    </div>
  )
}

export default StaticHero
