import React from "react"
import { Button } from "../../components/Button"
import Image from "../../components/Image"
import Text from "../../components/Text"

const LearnMoreCards = ({ post }) => {
  if (!post.hasThisSection) return ""
  const { blurb, button, heading, cards } = post

  return (
    <div className="info-cards color-back">
      <div className="columns info-heading">
        <div className="column is-5" />
        <div
          className="column has-text-centered"
          data-aos-duration="1200"
          data-aos="fade-left">
          <h3 className="has-text-centered">{heading}</h3>
          <Text text={blurb} />
        </div>
        <div className="column is-5" />
      </div>

      <div className="columns">
        <div className="column card-section">
          {cards &&
            cards.map((card, i) => (
              <div
                className="card-wrapper"
                key={i}
                data-aos-duration="1200"
                data-aos="fade-up"
                data-aos-delay={`${200 * i}`}>
                <div className="info-card">
                  <Image publicId={card.imageId} />

                  <div className="card-content">
                    <Text
                      as="h4"
                      className="card-heading"
                      text={card.heading}
                    />

                    <Text className="card-p" text={card.blurb} />
                  </div>
                  {card.button.href && card.button.buttonText && (
                    <Button
                      className="info-button"
                      contained
                      internal
                      href={card.button.href}
                      buttonText={card.button.buttonText}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="columns has-text-centered">
        <div className="column">
          <Button {...button} contained />
        </div>
      </div>
    </div>
  )
}

export default LearnMoreCards
